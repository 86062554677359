import { Maquinas, Apontamentos } from "./../app/common/interfaceApp";
import { defineStore } from "pinia";
import { useAppStore } from "./app";

export const useAppMaquinas = defineStore({
  id: "appMaquinas",
  state: (): {
    resourceMaquinas: Maquinas[];
  } => ({
    resourceMaquinas: [],
  }),

  getters: {},
  actions: {
    set_tp_parada(Apontamento : Apontamentos) {
        if (Apontamento.tipo == 'Parada') {
            if ((Apontamento.manutencao == 1) && (Apontamento.setup == 0)) { return 'Manutenção';}
            else if ((Apontamento.setup == 1) && (Apontamento.manutencao == 0)) {return 'Setup'; }
            else { return 'Parada'; }
        } else { return null; }
    },

    async listar_maquinas() {
      try {
        this.resourceMaquinas = [];

        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_recurso",
          { limit: 15 }
        );

        res.message.forEach((maquina: any) => {
          const apontamentos: Apontamentos[] = [];
          
          console.log('MAQUINAS ' + maquina.name,maquina);
          if (Array.isArray(maquina.apontamentos)) {
            maquina.apontamentos.forEach((apontamento: Apontamentos) => {
              apontamentos.push({
                name: apontamento.name,
                nome_operador: apontamento.nome_operador,
                recurso: apontamento.recurso,
                desc_recurso: apontamento.desc_recurso,
                dt_inicio: apontamento.dt_inicio,
                dt_fim: apontamento.dt_fim,
                ordem_de_producao: apontamento.ordem_de_producao,
                status: apontamento.status,
                observacao : apontamento.observacao,
                tipo : apontamento.tipo,
                serial : apontamento.serial,
                motivo_de_parada : apontamento.motivo_de_parada,
                desc_motivo_parada : apontamento.desc_motivo_parada,
                origem_apontamento : apontamento.origem_apontamento,
                turno : apontamento.turno,
                status_sensor: apontamento.status_sensor,
                motivo_de_parada_manutencao_setup : this.set_tp_parada(apontamento),
                setup : apontamento.setup,
                manutencao : apontamento.manutencao
              });
            });
          }

          this.resourceMaquinas.push({
            id: maquina.name,
            imagem: maquina.imagem,
            leitor: maquina.leitor,
            centro_de_custo: maquina.centro_de_custo,
            nome: maquina.nome,
            operador_padrao: maquina.operador_padrao,
            tipo_apontamento: maquina.tipo_apontamento,
            apontamentos: apontamentos,
          }); 
             
          const apontamentoAberto = maquina.apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");
            if (apontamentoAberto) { useAppStore().set_apontamento_realtime(apontamentoAberto); }
            useAppStore().apontamentos_historico = apontamentos.filter((apontamento: Apontamentos) => apontamento.status !== "Aberto")
        });
      } catch (error) { throw new Error(error); }
    },

    update_recurso_realtime(recurso_doc : any) {
      const new_data = {
        id: recurso_doc.name,
        imagem: recurso_doc.imagem,
        leitor: recurso_doc.leitor,
        centro_de_custo: recurso_doc.centro_de_custo,
        nome: recurso_doc.nome,
        operador_padrao: recurso_doc.operador_padrao,
        tipo_apontamento: recurso_doc.tipo_apontamento,
        apontamentos: recurso_doc.apontamentos,
      };

      const found = this.resourceMaquinas.find((data) => data.id == recurso_doc.name);

      if (found) {
        const new_resource = this.resourceMaquinas.map((data) =>
          data.id == recurso_doc.name ? new_data : data
        );
        
        this.resourceMaquinas = new_resource;
      }

      else { this.resourceMaquinas.push(new_data) }
    },

    update_apontamento_realtime(d) {
      if (Object.keys(d).includes("recurso")) {
        const selected_recurso_id = useAppStore().maquina_sel.id;
        
        const { apontamento, recurso } = d;
        
        const prev_resource = this.resourceMaquinas;
        
        const new_resource = prev_resource.map((data) => {
          if (data.id != recurso.name) return data;
          
          if (!data.apontamentos)
            return { ...data, apontamentos: [apontamento] };
          
          if (data.apontamentos.length == 0)
            return { ...data, apontamentos: [apontamento] };
          
          const found = data.apontamentos.find(
            (apt) => apt.name == apontamento.name
          );
          
          if (!found) {
            let new_apontamentos = data.apontamentos;
            new_apontamentos.unshift(apontamento)
          
            if (selected_recurso_id == recurso.name) { useAppStore().apontamentos_historico = new_apontamentos;}
            return {
              ...data,
              apontamentos: new_apontamentos,
            };
          }

          const new_apontamentos = data.apontamentos.map(e => e.name == apontamento.name ? apontamento : e);
          if (selected_recurso_id == recurso.name) {
            useAppStore().apontamentos_historico = new_apontamentos
              .filter((apontamento: Apontamentos) => apontamento.status !== "Aberto")
              .map((apontamento: Apontamentos) => ({
              ...apontamento,
              motivo_de_parada_manutencao_setup: this.set_tp_parada(apontamento)
              }));

            const apontamentoAberto = new_apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");
            apontamentoAberto.motivo_de_parada_manutencao_setup = this.set_tp_parada(apontamentoAberto);
            if (apontamentoAberto) { useAppStore().set_apontamento_realtime(apontamentoAberto); }
          }

          const new_data = { ...data, apontamentos: new_apontamentos };
          return new_data;
        });

        this.resourceMaquinas = new_resource;
      }
    },
  }, persist: { enabled: true }
});
