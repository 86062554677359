<template>
    <v-dialog v-model="appCharts.open_disponibilidade_resumida" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <div class="status_mchine">
                <v-card class="ml-2 mr-2">
                    <v-toolbar color="primary">
                        <v-btn icon="mdi-close" @click="appCharts.open_disponibilidade_resumida = false"></v-btn>
                    </v-toolbar>
                    <v-row>
                        <v-col cols="4" width=" 100%" height="70px" class="d-flex justify-start">
                            <v-menu>
                                <template v-slot:activator="{ props }">
                                    <v-btn class="mt-1 ml-2" height="55px" width="40%" color="primary" v-bind="props">
                                        RECARREGAR EM:
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, index) in items" :key="index" :value="index">
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-select class="mt-1 ml-5" label="MÁQUINAS"
                                :items="['32- CENTRO DE USINAGEM CNC HELLER BEA 2', '36- CENTRO DE USINAGEM CNC HELLER NC 80 C BZ']"
                                multiple variant="outlined" style="height: 4px; width: 100%;"></v-select>
                        </v-col>
                        <v-col cols="4" class="d-flex justify-space-around">
                            <v-select class="mt-1 ml-2 mr-3" label="GRUPOS"
                                :items="['Todos', 'CENTRO DE USINAGEM', 'FRESA CNC']" multiple variant="outlined"
                                style="height: 4px;">
                            </v-select>
                            <v-btn style="height: 90%" color="#4CAF50" class="mt-1 mr-4" size="x-large">APLICAR</v-btn>
                            <v-btn color="primary" class="mt-1 mr-4" icon="mdi-reload"
                                @click="appCharts.open_disponibilidade_resumida = false"></v-btn>
                            <v-btn color="#4CAF50" class="mt-1 mr-4" icon="mdi-share-variant"
                                @click="appCharts.open_disponibilidade_resumida = false"></v-btn>
                        </v-col>
                        <v-col cols="4" width="100%" height="70px">
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-card width=" 100%" height="50px">
                            <v-row style="height: 100%;">
                                <v-col cols="4">
                                    <v-card sm="4" class="text-start d-flex rounded ml-3"
                                        style="width: 101% ;background-color: #4CAF50;">
                                        <h4 style="color: #FFFFFF; font-size: 150%; height:25px;" class=" mb-3 ml-4">
                                            <strong>PRODUÇÃO</strong>
                                        </h4>
                                    </v-card>
                                </v-col>

                                <v-col cols="4">
                                    <v-card sm="4" class="text-center d-flex rounded"
                                        style=" width: 101%; background-color: #C62828;">
                                        <h4 style="color: #FFFFFF; font-size: 150%; height: 25px" class=" mb-3 ml-4">
                                            <strong>PARADA</strong>
                                        </h4>
                                    </v-card>
                                </v-col>

                                <v-col cols="4" sm="4">
                                    <v-row>
                                        <v-col cols="12" md="4" class="px-0">
                                            <v-card class="d-flex align-center rounded"
                                                style="height: 35px; background-color: #FB8C00;">
                                                <h4 style="color: #FFFFFF; font-size: 1.5rem;" class="ml-2 mb-2">
                                                    <small>SETUP</small>
                                                </h4>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" md="4" class="px-0">
                                            <v-card class="d-flex align-center rounded"
                                                style="height: 35px; background-color: #2979FF;">
                                                <h4 style="color: #FFFFFF; font-size: 1.5rem;" class="ml-2 mb-2 ">
                                                    <small>MANUTENÇÃO</small>
                                                </h4>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" md="4" class="px-0">
                                            <v-card class="d-flex align-center rounded mr-md-6"
                                                style="height: 35px; background-color: gray;">
                                                <h4 style="color: #FFFFFF; font-size: 1.5rem;" class="ml-2 mb-2">
                                                    <small>DESLIGADA</small>
                                                </h4>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-row>
                </v-card>
            </div>
            <v-row class="mt-0">
                <v-col cols="12" sm="4" style="height: 100vh;">
                    <v-card class="ml-2" style="width: 101%; height: 100%; background-color: grey">
                        <v-row justify="start;">
                            <v-col cols="12">
                                <v-card class="d-flex mt-2 ml-2 mr-2" style="height: 150px;  padding-right: 16px"
                                    color="#4CAF50">
                                    <v-col cols="2" style="background-color: #388E3C;"
                                        class="d-flex justify-center align-center">
                                        <v-icon icon="mdi-lan-connect" size="40"></v-icon>
                                    </v-col>
                                    <v-col col="10">
                                        <v-row>
                                            <v-col>
                                                <v-row class="d-flex flex-column align-start">
                                                    <small class="ml-4"
                                                        style="font-size: 25px; font-weight: bold; text-transform: uppercase;">MAQUINA</small>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="d-flex flex-column align-start" xs="12" sm="6" md="4">
                                            <v-col cols="12">
                                                <v-alert color="#FFEA00" type="warning" title="Atenção"
                                                    style="height: 90px;width: 115%;">
                                                    <small
                                                        style="font-size: 25px; font-weight: bold; text-transform: uppercase;">O.P
                                                        Não
                                                        Informada</small>
                                                </v-alert>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1">
                                        <div class="d-flex flex-column align-end">
                                            <small
                                                style="font-size: 20px; font-weight: bold; text-transform: uppercase;">(time_formatado)</small>
                                        </div>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="4" style="height: 100vh">
                    <v-card style="width: 101%; height: 100%; background-color: grey">
                        <v-row justify="start;">
                            <v-col cols="12">
                                <v-card class="d-flex mt-2 ml-2 mr-2" style="height: 150px; padding-right: 16px"
                                    color="#C62828">
                                    <v-col cols="2" style="background-color: #BF360C;"
                                        class="d-flex justify-center align-center">
                                        <v-icon icon="mdi-lan-connect" size="40"></v-icon>
                                    </v-col>
                                    <v-col col="10">
                                        <v-row>
                                            <v-col>
                                                <v-row class="d-flex flex-column align-start">
                                                    <small class="ml-4"
                                                        style="font-size: 25px; font-weight: bold; text-transform: uppercase;">MAQUINA</small>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="d-flex flex-column align-start" xs="12" sm="6" md="4">
                                            <v-col cols="12">
                                                <v-alert color="#EF5350" type="warning" title="Atenção"
                                                    style="height: 90px;width: 115%;">
                                                    <small
                                                        style="font-size: 25px; font-weight: bold; text-transform: uppercase;">O.P
                                                        Não
                                                        Informada</small>
                                                </v-alert>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1">
                                        <div class="d-flex flex-column align-end">
                                            <small
                                                style="font-size: 20px; font-weight: bold; text-transform: uppercase;">(time_formatado)</small>
                                        </div>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="4" class="pl-1" style="height: 100vh">
                    <v-card class="mr-2" style="height: 100%; background-color: grey">
                        <v-row justify="start">
                            <v-col cols="12">
                                <v-card class="d-flex mt-2 ml-2 mr-2" style="height: 150px; padding-right: 16px"
                                    color="#2B353B">
                                    <v-col cols="2" style="background-color: #3C464E"
                                        class="d-flex justify-center align-center">
                                        <v-icon icon="mdi-lan-disconnect" size="40"></v-icon>
                                    </v-col>
                                    <v-col col="10">
                                        <v-row>
                                            <v-col>
                                                <v-row class="d-flex flex-column align-start">
                                                    <small class="ml-4"
                                                        style="font-size: 25px; font-weight: bold; text-transform: uppercase;">MAQUINA</small>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1">
                                        <div class="d-flex flex-column align-end">
                                            <small
                                                style="font-size: 20px; font-weight: bold; text-transform: uppercase;">(time_formatado)</small>
                                        </div>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>




<script>
import { useAppCharts } from '../../../stores/appCharts';

export default {
    name: "charts_recursos_por_status",
    data() {
        return {
            dialog: true,
            producao: [],
            parada: [],
            setup_manutencao_offline: [],
            items: [
                { title: 'OFF' },
                { title: 'AUTO' },
                { title: '5S' },
                { title: '10S' },
                { title: '30S' },
                { title: '1M' },
                { title: '5M' },
                { title: '10M' },
                { title: '15M' },
                { title: '30M' },
                { title: '1H' },
                { title: '2H' },
                { title: '1D' }
            ]
        }
    },
    computed: {
        appCharts() {
            return useAppCharts();
        }
    },
    async mounted() {
        await this.appCharts.get_all_apontamentos_abertos();
        this.producao = this.appCharts.producao;
        this.parada = this.appCharts.parada;
        this.setup_manutencao_offline = this.appCharts.setup_manutencao_offline;
        console.log(this.setup_manutencao_offline);
    },
    methods: {}
};
</script>

<!-- <style>
.producao-column {
    align-items: center;
    background-color: rgb(161, 255, 161);
}

.parada-column {
    align-items: center;
    background-color: rgb(255, 161, 161);
    padding-right: 0%;
}

.setup-manutencao-offline-column {
    align-items: center;
    background-color: rgb(179, 179, 179);
}

.header-producao {
    padding-bottom: 1%;
    padding-top: 1%;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 192, 0);
}

.header-parada {
    padding-bottom: 1%;
    padding-top: 1%;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 79, 79);
}

.header-setup-manutencao-offline {
    padding-bottom: 1%;
    padding-top: 1%;
    color: rgb(255, 255, 255);
    background-color: rgb(129, 129, 129);
}
</style> -->
